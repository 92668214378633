<template>
  <v-row class="my-3">
    <v-col md="12" lg="12">
      <v-card class="px-5 py-5" elevation="3">
        <v-row>
          <!--Select fincial period-->
          <v-col md="3" sm="3">
            <v-select
              v-model="financial_period"
              :items="date_range_items"
              item-text="text"
              item-value="value"
              label="Financial Year"
              outlined
              flat
              solo
              dense
              @change="passFinancialPeriodFilter"
            >
            </v-select>
          </v-col>
          <!--Status Filter-->
          <v-col md="3" sm="3">
            <v-autocomplete
              :items="journalFilterArray"
              v-model="journal_account"
              item-text="account_name"
              :return-object="true"
              label="Journal Name"
              :disabled="!this.financial_period"
              outlined
              flat
              solo
              dense
              append-icon="mdi-filter-variant"
              @change="passJournalFilter"
              hint="You first need to choose a financial year"
              :persistent-hint="!this.financial_period"
            >
            </v-autocomplete>
          </v-col>
          <!--Search Bar-->
          <v-col md="3" sm="3">
            <v-text-field
              v-model="search"
              label="Search"
              outlined
              flat
              solo
              dense
              clearable
              append-icon="mdi-magnify"
              @input="passSearch"
              @click:clear="clearSearch"
            >
            </v-text-field>
          </v-col>
          <!--Divider-->
          <v-spacer></v-spacer>
          <v-divider class="mx-4" vertical></v-divider>
          <!--Ledger total-->
          <v-col md="2" sm="2">
            <div v-if="filtered_entries.length > 0">
              <h3 class="text-right">
                Total:
                <span>{{
                  formatAsCurrency(
                    "R",

                    filteredDate.reduce((total, item) => {
                      return total + item.entry_amount;
                    }, 0)
                  )
                }}</span>
              </h3>
            </div></v-col
          >
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { formatAsCurrency } from "../../../../composables/external";
import {
  buildDateRangeFilter,
  createChartOfAccountsDropdown,
} from "../../data/external_accounting";
export default {
  name: "JournalEntryFilters",
  props: [
    "filtered_entries",
    "fiscal_year_start",
    "first_fiscal_year",
    "filteredDate",
  ],

  data() {
    return {
      accounts: [],
      account_headers: [],
      journalFilterArray: [],
      journal_account: null,
      date_range_items: [],
      financial_period: null,
      search: "",
    };
  },
  created() {
    this.createChartOfAccountsDropdown();
  },
  beforeUpdate() {
    this.buildDateRangeFilter();
  },
  methods: {
    // Date range
    buildDateRangeFilter,
    formatAsCurrency,
    createChartOfAccountsDropdown,
    passJournalFilter() {
      this.$emit("changeJournal", this.journal_account);
    },
    passFinancialPeriodFilter() {
      this.$emit("changeFinancialPeriod", this.financial_period);
    },
    passSearch() {
      this.$emit("changeSearch", this.search);
    },
    clearSearch() {
      this.$emit("clearSearch", "");
    },
  },
};
</script>
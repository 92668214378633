import db from "../../../components/firebaseInit";
export const buildDateRangeFilter = function () {
    // Add start date to dropdown
    // Get the starting month of accounting period
    // Get the last month of the accounting period
    // Construct date range items for each year until current year
    for (
        let i = this.first_fiscal_year;
        i < new Date().getFullYear() + 1;
        i++
    ) {
        const date_start = new Date(i, this.fiscal_year_start).toLocaleString(
            "en-us",
            { month: "short" }
        );
        // Get the month of end of accounting period
        const date_end = new Date(
            i + 1,
            this.fiscal_year_start - 1
        ).toLocaleString("en-us", { month: "short" });
        const period = {
            text: `${date_start} ${i} - ${date_end} ${i + 1}`,
            value: `${i}-${this.fiscal_year_start + 1 < 10 ? (this.fiscal_year_start + 1).toString().padStart(2, '0')
                : this.fiscal_year_start + 1} - ${i + 1}-${this.fiscal_year_start < 10 ? this.fiscal_year_start.toString().padStart(2, '0')
                    : this.fiscal_year_start}`
        }
        this.date_range_items.push(period);
    }
}

//
export const createChartOfAccountsDropdown = function () {
    const docRef = db
        .collection("chart_of_accounts")
        .orderBy("account_sub_type");
    docRef
        .get()
        //  Get all accounts from database
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                this.accounts.push(data);
            });
            //  Create array of ledger types to use as headers
            this.accounts.map((item) => {
                this.account_headers.push(item.account_sub_type);
            });
            //  Remove duplicate values from array
            let uniqueHeaderArray = [...new Set(this.account_headers)];
            //  Push header to selection menu
            uniqueHeaderArray.forEach((header) => {
                //    Push header to dropdown menu
                this.journalFilterArray.push({ header: header });
                //  Filter accounts by header
                const filteredAccounts = this.accounts.filter((account) => {
                    return account.account_sub_type === header;
                });
                //  Push each account to the dropdown menu as an object
                filteredAccounts.forEach((account) => {
                    this.journalFilterArray.push({
                        account_name: account.account_name,
                        group: account.account_sub_type,
                        account_id: account.account_id,
                    });
                });
                //  Add a dividr to seperate the headers
                this.journalFilterArray.push({ divider: true });
            });
        })
        .catch((error) => error);
}
/*
    ACCOUNTING TOTALS FUNCTIONS
    -- These functions all relate to updating the chart of accounts
    -- in each of the "year-month" documents under the "accounting_totals"
    -- collection
*/
export const getYearMonthDocs = function () {
    const docRef = db.collection("accounting_totals")
    docRef.get().then((querySnapshot) => {
        this.year_month_docs = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            this.year_month_docs.push(data)
        })
    }).catch((error) => error)
}